<template>
  <div class="section--desc mt-5" v-if="listings && listings.length > 0">
    <div class="subtitle--text">{{ $t('general.listListing') }}</div>
    <div v-if="$mq === 'xs'">
      <hr />
      <div class="project--listing" v-for="(prop, v) in listings" :key="v">
        <div class="row ma-0" @click="navigateListing(prop.web_url)">
          <div class="col-auto ma-auto pa-0">
            <div class="listing--unit pa-2">
              {{ prop.unit ? prop.unit : '-' }}
            </div>
          </div>
          <div class="col">
            <div class="unit--name">{{ prop.unit_name }}</div>
            <div class="mt-1" v-if="prop.floor !== undefined">
              {{ $t('general.floorLoc') }}:
              {{ prop.floor ? `${prop.floor}` : `-` }}
            </div>
            <div class="mt-1" v-if="prop.total_number_of_floor !== undefined">
              {{ $t('general.totalFloor') }}:
              {{ prop.total_number_of_floor ? `${prop.total_number_of_floor}` : `-` }}
            </div>
            <div class="mt-1">
              <span class="mr-2 align-middle">
                {{ $t('addListing.landSizeAbbr') }}:
                {{ prop.land_size ? `${prop.land_size} m²` : `-` }}
              </span>
              <span class="mr-2 align-middle">
                {{ $t('addListing.buildingSizeAbbr') }}:
                {{ prop.building_size ? `${prop.building_size} m²` : `-` }}
              </span>
              <i class="icon icon--md icon--bed mr-2"></i
              >{{ prop.bedroom ? `${prop.bedroom}` : `-` }}
              <i class="icon icon--md icon--bath ml-2 mr-1"></i
              >{{ prop.bathroom ? `${prop.bathroom}` : `-` }}
            </div>
            <div class="sell--price mt-1">{{ prop.sell_price }}</div>
          </div>
          <div class="col-auto pl-0">
            <span class="ion-chevron-right"></span>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <table class="table table-striped table-hover project--partner-table" v-else>
      <thead>
        <tr>
          <!--          $t('tos.bookmark')-->
          <th scope="col">{{ $t('general.unitName') }}</th>
          <th scope="col">{{ $t('general.unit') }}</th>
          <th scope="col">{{ $t('addListing.landSize') }}</th>
          <th scope="col">{{ $t('addListing.buildingSize') }}</th>
          <th scope="col">
            {{
              projectPartner.property_type_id === 27
                ? $t('general.floor')
                : $t('general.totalFloor')
            }}
          </th>
          <th scope="col">{{ $t('general.bedroom') }}</th>
          <th scope="col">{{ $t('general.bathroom') }}</th>
          <th scope="col">{{ $t('general.sellPrice') }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody style="cursor: pointer">
        <tr v-for="(prop, v) in listings" :key="v" @click="navigateListing(prop.web_url)">
          <td>{{ prop.unit_name ? prop.unit_name : '-' }}</td>
          <td>{{ prop.unit ? prop.unit : '-' }}</td>
          <td>{{ prop.land_size ? `${prop.land_size} m²` : `-` }}</td>
          <td>{{ prop.building_size ? `${prop.building_size} m²` : `-` }}</td>
          <td>
            {{
              projectPartner.property_type_id === 27
                ? prop.floor
                  ? `${prop.floor}`
                  : `-`
                : prop.total_number_of_floor
                ? `${prop.total_number_of_floor}`
                : `-`
            }}
          </td>
          <td>{{ prop.bedroom ? `${prop.bedroom}` : `-` }}</td>
          <td>{{ prop.bathroom ? `${prop.bathroom}` : `-` }}</td>
          <td>{{ prop.sell_price }}</td>
          <td>
            <a class="btn btn-primary" :href="prop.web_url">{{ $t('general.seeDetail') }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-space-between py-3 px-2 bg--grey" v-if="brosur">
      <span>{{ $t('general.brochureMsg') }}</span>
      <a :href="brosur" target="_blank" class="btn btn-primary--outline mr-2">
        <i class="ion-android-download mr-2"></i>
        <span>{{ $t('general.downloadBrochure') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listings: {
      type: Array,
    },
    projectPartner: {
      required: true,
    },
    brosur: {
      type: String,
    },
  },
  methods: {
    navigateListing(url) {
      console.log('url', url);
      this.$router.push(url);
    },
  },
};
</script>
